import React from 'react';

export const PhotoCard = ({ owner, id, filename, imageUrl, onClick }) => {
    return (
        <div className="w-3 rounded overflow-hidden shadow-lg m-1">
            <img className="w-full h-64 object-center" src={imageUrl} onClick={onClick} />
            <div class="px-1 py-1">
                <div class="font-regular text-xs mb-1">{id}:{filename}</div>
            </div>
        </div>
    );
}