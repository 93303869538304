import { Grid2 } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, {
	useEffect,
	useState
} from "react";
import { useNavigate } from "react-router-dom";
import { apiQuery, dbQuery } from "../../api/db";
import { INDIVIDUAL_COLUMNS } from "../../headers/ColumnHeader";
import Button from "../UI/Button";
import Card from "../UI/Card";
import "./Individuals.css";
import Posts from "./Posts";
import { debug } from "../../utils/ConsoleHelper";

const AlbumList = (props) => {
	let col_headers = INDIVIDUAL_COLUMNS;
	const [rawData, setRawData] = useState();
	const navigate = useNavigate();
	const [directorystr, setDirectorystr] = useState()
	const [dirs, setDirs] = useState()

	useEffect(() => {
		async function getddata() {
			const data = getApiData("listdirectory")
			return data
		}
		const d = getddata()
		debug(d)
	}, [])

	const getApiData = async (querystr) => {
		const data = await apiQuery(querystr);
		setDirs(data)
		return data
	};

	const getData = async (querystr) => {
		const data = await dbQuery(querystr);
		setRawData(data);
	};
	const FetchLienTran = () => {
		setDirectorystr("lientran")
		navigate(`/person/posts?dir=lientran`)
	};
	const FetchAnhDam = () => {
		setDirectorystr("anhdam")
		navigate(`/person/posts?dir=anhdam`)
	};
	const Fetch = (dirstr) => {
		setDirectorystr(dirstr)
		navigate(`/person/posts?dir=${dirstr}`)
	};
	//debug(directorystr)
	return (
		<Card className="table">
			<div>
				<h5 style={{ color: "red", margin: "0.5rem" }}> Photo Albums</h5>
				<Grid2 container direction="column" spacing={2}>
					{/* <Grid2 item xs="12" >
						<Button onClick={FetchLienTran} text="Bà Trần Thị Liên"></Button>
						<Button onClick={FetchAnhDam} text="Ông Đàm Mạnh Anh"></Button>
					</Grid2> */}
					<Grid2 item xs="12" >
						<ul>
							{dirs && dirs.length > 0 && dirs.map((dir) => {
								return (<li className="mb-2">
									<Button onClick={() => Fetch(dir)} text={`Album ${dir}`} > </Button>
								</li>)
							})}
						</ul>
					</Grid2>
				</Grid2>
				{/* {directorystr && <Posts directorystr={directorystr} />} */}
			</div>
		</Card>
	);
};

export default AlbumList;
