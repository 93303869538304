import "primeflex/primeflex.css"; // css utility
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css"; // core css
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import React, { useEffect } from "react";
import { Beforeunload } from "react-beforeunload";
import { Route, Routes } from "react-router-dom";
import Navigation from "./components/UI/Navigation";
import useZustandToken from "./components/useZustandToken";
import AlbumList from "./components/views/AlbumList";
import FrontPage from "./components/views/FrontPage";
import { NotFound } from "./components/views/NotFound";
import Posts from "./components/views/Posts";
import PreviewDirectoryImages from "./components/views/PreviewDirectoryImages";
import { ContactForm } from "./components/views/Contact";

const App = () => {
	const { token, setToken } = useZustandToken();
	useEffect(() => {
		console.log(token);
	}, [token]);
	if (!token?.token) {
		//return <Login setToken={setToken} />;
	}
	const removeApplicationData = () => {
		if (window.closed) {
			localStorage.clear();
		}
	};

	return (
		<>
			<Beforeunload onBeforeunload={removeApplicationData}>
				<Navigation />
				<Routes>
					<Route path="/" element={<FrontPage />}></Route>
					<Route path="/albums" element={<AlbumList />}></Route>
					<Route path="/person/dirimages" element={<PreviewDirectoryImages />}></Route>
					<Route path="/person/posts" element={<Posts />}></Route>
					<Route path="/contact" element={<ContactForm />}></Route>
					<Route path="*" element={<NotFound />}></Route>
				</Routes>
			</Beforeunload>
		</>
	);
};

export default App;
