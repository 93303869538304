import React from "react";
import Card from "../UI/Card";
import { CenterFocusStrong } from "@mui/icons-material";
import { TabView, TabPanel } from "primereact/tabview";
//import useToken from "../useToken";
import useZustandToken from "../useZustandToken";

import Login from "./Login";

function FrontPage() {
	const { token, setToken } = useZustandToken() // useToken();
	if (!token) {
		return <Login setToken={setToken} />;
	}

	return (
		<Card>
			<TabView>
				<TabPanel header="Home">
					<h2
						className="text-cyan-500"
						style={{ alignContent: CenterFocusStrong }}
					>
						Album - Hình Ảnh Gia Đình
					</h2>
				</TabPanel>
				{/* <TabPanel header="Tâm Sự">
				</TabPanel> */}
			</TabView>
		</Card>
	);
}

export default FrontPage;
